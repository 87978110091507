var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"action mr-5 mb-4"},[_c('div',{staticClass:"action-row d-flex align-center justify-space-between"},[_c('div',{staticClass:"action-row--col flex-grow-1 pr-5"},[_c('tp-search-option',{attrs:{"default-option":_vm.searchType,"options":_vm.searchOptions,"value":_vm.searchKey},on:{"search":function($event){return _vm.updateSearchKey($event)}}})],1),_c('div',{staticClass:"action-row--col"},[_c('v-btn',{staticClass:"rounded-lg mr-3",attrs:{"color":"primary","depressed":""},on:{"click":function($event){return _vm.showModalCreateGuarantee('guarantee')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Bảo hành ")],1),_c('v-btn',{staticClass:"rounded-lg mr-3",attrs:{"color":"primary","dark":"","depressed":""},on:{"click":function($event){return _vm.showModalCreateGuarantee('repair')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Sửa chữa ")],1),_c('v-menu',{attrs:{"max-height":"200px","nudge-top":"0px","offset-y":"","rounded":"lg","transition":"scroll-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-lg",attrs:{"color":"primary","dark":"","depressed":"","loading":[
                'loading-exportReportGuarantee',
                'loading-exportReportRepair'
              ].includes(_vm.guaranteeVoucherStatusRequest.value),"outlined":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-file-download-outline")]),_vm._v(" Xuất file ")],1)]}}])},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.$emit('exportReportGuarantee')}}},[_vm._v(" Bảo hành ")]),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.$emit('exportReportRepair')}}},[_vm._v(" Sửa chữa ")])],1)],1)],1)]),_c('div',{staticClass:"action-row d-flex align-center justify-space-between mt-4 gap-10 overflow-x-auto"},[_c('div',{staticClass:"action-row--col align-center"},[_c('div',{staticClass:"action-row d-flex align-center justify-space-between gap-2"},[_vm._l((_vm.filterDueDateTemp),function(item){return [(item.count !== 0)?_c('v-btn',{key:item.value,staticClass:"font-weight-regular text-body-1 text-none",attrs:{"color":item.active ? 'primary' : 'white',"elevation":"0","height":"40"},on:{"click":function($event){return _vm.selectDueDateFilter(item)}}},[_vm._v(" "+_vm._s(item.label)+" "),_c('v-badge',{attrs:{"color":item.active
                  ? 'white'
                  : item.value === 0
                  ? 'red darken-1'
                  : 'grey lighten-3',"inline":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('span',{class:item.active
                      ? 'primary--text'
                      : item.value === 0
                      ? 'text-white'
                      : 'text-black'},[_vm._v(" "+_vm._s(item.count)+" ")])]},proxy:true}],null,true)})],1):_vm._e()]})],2)]),_c('div',{staticClass:"action-row--col d-flex align-center"},[_c('tp-btn-overflow',{attrs:{"items":_vm.showFilterList,"selected-value":_vm.selectedShowFilterId,"btn-class":"white rounded-lg mr-5 flex-shrink-0"},on:{"change":function($event){_vm.selectedShowFilterIndex = $event},"select":function($event){return _vm.selectShowFilter($event)}}}),_c('div',{staticClass:"text-body-2 flex-shrink-0"},[_vm._v(" "+_vm._s(_vm.paginationInfo.from)+" - "+_vm._s(_vm.paginationInfo.to)+" trong số "+_vm._s(_vm.paginationInfo.itemTotal)+" ")]),_c('v-pagination',{attrs:{"length":_vm.paginationInfo.pageTotal,"total-visible":5,"color":"primary"},model:{value:(_vm.curPage),callback:function ($$v) {_vm.curPage=$$v},expression:"curPage"}})],1)]),_c('modal-create-guarantee-voucher',{attrs:{"type":_vm.typeModalCreate}}),_c('modal-add-outer-serial',{attrs:{"guaranteeVoucherStatusRequest":_vm.guaranteeVoucherStatusRequest}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }