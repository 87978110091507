<template>
  <div class="filter-area">
    <div class="font-weight-bold text-h5 mb-4">Phiếu bảo hành/sửa chữa</div>
    <v-card class="tp-filter-scroll pa-5" flat>
      <div>
        <div class="font-weight-bold mb-2">Chi nhánh</div>
        <v-autocomplete
          v-model="selectedBranches"
          class="tp-filter-autocomplete"
          :items="branches"
          dense
          deletable-chips
          hide-details
          hide-selected
          item-text="name"
          item-value="id"
          no-data-text="Không có dữ liệu"
          outlined
          chips
          single-line
          small-chips
          label="Outlined"
          multiple
          placeholder="Chọn chi nhánh"
          :disabled="statusRequest.value === 'loading-getGuaranteeVouchers'"
        ></v-autocomplete>
      </div>

      <div class="mt-5">
        <div class="font-weight-bold mb-2">Nhân viên tạo</div>
        <v-autocomplete
          v-model="selectedEmployees"
          class="tp-filter-autocomplete"
          :items="employees"
          dense
          deletable-chips
          hide-details
          hide-selected
          item-text="name"
          item-value="id"
          no-data-text="Không có dữ liệu"
          outlined
          chips
          single-line
          small-chips
          label="Outlined"
          multiple
          placeholder="Chọn nhân viên"
          :disabled="statusRequest.value === 'loading-getGuaranteeVouchers'"
        ></v-autocomplete>
      </div>

      <div class="mt-5">
        <div class="font-weight-bold mb-2">Loại phiếu</div>
        <v-radio-group
          v-model="selectedType"
          class="mt-0"
          dense
          hide-details
          :disabled="statusRequest.value === 'loading-getGuaranteeVouchers'"
        >
          <v-radio label="Tất cả" value="all"></v-radio>
          <v-radio label="Bảo hành" :value="1"></v-radio>
          <v-radio label="Sửa chữa" :value="2"></v-radio>
        </v-radio-group>
      </div>

      <div class="mt-5">
        <div class="font-weight-bold mb-2">Mức độ rủi ro</div>
        <v-checkbox
          v-model="selectedRisk"
          class="mt-1"
          dense
          hide-details
          v-for="item in RISK_STATUSES"
          :key="item.value"
          :value="item.value"
          :disabled="statusRequest.value === 'loading-getGuaranteeVouchers'"
        >
          <template #label>
            {{ item.label }}

            <v-tooltip
              content-class="elevation-4 px-2"
              max-width="250px"
              nudge-top="5px"
              bottom
            >
              <template #activator="{ on }">
                <v-icon class="ml-1" color="grey" dark small v-on="on">
                  mdi-information
                </v-icon>
              </template>
              <span>{{ item.tooltip }}</span>
            </v-tooltip>
          </template>
        </v-checkbox>
      </div>

      <div class="mt-5">
        <div class="font-weight-bold mb-2">Trạng thái</div>
        <v-checkbox
          v-model="selectedStatus"
          class="mt-1"
          dense
          hide-details
          v-for="item in GUARANTEE_STATUSES"
          :label="item.label"
          :key="item.value"
          :value="item.value"
          :disabled="statusRequest.value === 'loading-getGuaranteeVouchers'"
        ></v-checkbox>
      </div>

      <div class="mt-5">
        <div class="font-weight-bold mb-2">Trạng thái hủy</div>
        <v-radio-group
          v-model="selectedCancelStatus"
          class="mt-1"
          dense
          hide-details
          :disabled="statusRequest.value === 'loading-getGuaranteeVouchers'"
        >
          <v-radio label="Tất cả" :value="CANCEL_STATUSES.ALL"></v-radio>
          <v-radio
            label="Chưa hủy"
            :value="CANCEL_STATUSES.NOT_CANCELED_YET"
          ></v-radio>
          <v-radio label="Đã hủy" :value="CANCEL_STATUSES.CANCELLED"></v-radio>
        </v-radio-group>
      </div>

      <div class="mt-5">
        <div class="font-weight-bold mb-2">Thời gian</div>
        <tp-input-time-filter
          :type="dateFilter.type"
          :value="dateFilter.value"
          @change="updateDateFilter($event)"
          :disabled="statusRequest.value === 'loading-getGuaranteeVouchers'"
        ></tp-input-time-filter>
      </div>
    </v-card>
  </div>
</template>

<script>
import {
  CANCEL_STATUSES,
  GUARANTEE_STATUSES,
  RISK_STATUSES
} from "@/modules/Guarantee/constants";

export default {
  props: {
    branchesFilter: {
      type: Array
    },
    cancelStatusFilter: {
      type: Number
    },
    dateFilter: {
      type: Object
    },
    employeesFilter: {
      type: Array
    },
    receivedBranchFilter: {
      type: Number
    },
    riskFilter: {
      type: Array
    },
    statusFilter: {
      type: Array
    },
    typeFilter: {
      type: [Number, String]
    }
  },
  data() {
    return {
      CANCEL_STATUSES,
      GUARANTEE_STATUSES,
      RISK_STATUSES
    };
  },
  computed: {
    statusRequest() {
      return this.$store.getters["GUARANTEE_VOUCHER/statusRequest"];
    },
    branches() {
      return this.$store.getters["BRANCH/allBranches"];
    },
    employees() {
      return this.$store.getters["EMPLOYEE/allEmployees"];
    },
    selectedBranches: {
      get() {
        return this.branchesFilter;
      },
      set(val) {
        this.$emit("updateBranchesFilter", val);
      }
    },
    selectedCancelStatus: {
      get() {
        return this.cancelStatusFilter;
      },
      set(val) {
        this.$emit("updateCancelStatusFilter", val);
      }
    },
    selectedEmployees: {
      get() {
        return this.employeesFilter;
      },
      set(val) {
        this.$emit("updateEmployeesFilter", val);
      }
    },
    selectedStatus: {
      get() {
        return this.statusFilter;
      },
      set(val) {
        this.$emit("updateStatusFilter", val);
      }
    },
    selectedRisk: {
      get() {
        return this.riskFilter;
      },
      set(val) {
        this.$emit("updateRiskFilter", val);
      }
    },
    selectedType: {
      get() {
        return this.typeFilter;
      },
      set(val) {
        this.$emit("updateTypeFilter", val);
      }
    }
  },
  async created() {
    if (this.branches.length === 0) {
      await this.$store.dispatch("BRANCH/getAllBranches");
    }
    if (this.employees.length === 0) {
      await this.$store.dispatch("EMPLOYEE/getAllEmployees");
    }
  },
  methods: {
    updateDateFilter(val) {
      this.$emit("updateDateFilter", val);
    }
  }
};
</script>

<style lang="scss" scoped>
.filter-area {
  width: 256px;
  .tp-filter-scroll {
    overflow-y: auto;
  }
}
</style>
